.HowItWorksSection {
  padding: 4rem 2rem;
  background-color: #f9f8fd;
}

.HowItWorksSection__steps {
  display: flex;
  max-width: 1200px;
  margin: 2rem auto;
}

.HowItWorksSection__step {
  padding: 0 1rem;
  margin: 0 auto 1rem;
  max-width: 300px;

  .title {
    font-size: 1.2rem;
    font-weight: 400;
    color: #020db0;
    margin: 0 0 0.5rem;
  }

  .description {
    text-align: left;
  }

  .image {
    margin-top: 1rem;

    img {
      width: 100%;
      border: 1px solid #999;
    }
  }
}

.HowItWorksSection__highlights {
  margin: 2rem auto 0;
  font-weight: 500;
  max-width: 410px;

  .outcome {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 700px) {
  .HowItWorksSection__steps {
    display: block;
  }
}