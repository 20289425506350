.TestimonialSection {
  padding: 4rem 2rem;
}

.Testimonials {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 2rem auto;
}

.Testimonial {
  flex: 0 0 33.333333%;
  position: relative;
  padding: 1rem;
  margin: 0 auto 1rem;
  text-align: left;
  max-width: 300px;
  height: 400px;
  background-color: #fff;
}

.Testimonial__content {
  p {
    text-align: left;
  }
}

.Testimonial__user {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  color: #020db0;

  .name {
    font-weight: 400;
  }
}

@media only screen and (max-width: 700px) {
  .Testimonials {
    display: block;
  }

  .Testimonial {
    padding: 0;
    margin-bottom: 2rem;
    height: 350px;
    border-right: 0;
    border-bottom: 1px solid #ccc;
  }

  .Testimonial__user {
    left: 0;
  }
}

@media only screen and (max-width: 350px) {
  .Testimonial {
    height: 420px;
  }
}
