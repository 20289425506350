.FAQsSection {
  padding: 4rem 2rem;
  background-color: #fff;
}

.FAQsSection__items {
  max-width: 600px;
  margin: 2rem auto 0;
  text-align: left;
}

.FAQsSection__item {
  margin: 0 0 2rem;

  &:last-child {
    margin: 0;
  }

  p {
    text-align: left;
  }

  ul {
    margin: 0 0 1rem;
  }

  li {
    margin: 0 0 0.2rem;
  }
}

.FAQsSection__question {
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0 0 0.5rem;
}

.FAQsSection__answer {
  padding-left: 0.5rem;
}