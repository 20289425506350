html, * {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 300;
  color: #333;
}

a {
  color: #333;
  text-decoration: none;

  &:hover {
    color: #333;
    text-decoration: none;
    padding: 0 0 0.2rem;
    border-bottom: 2px solid #333;
  }
}

a.link {
  color: #3f9e99;
  border: none;
  text-decoration: underline;
}

a.no-style {
  text-decoration: none;
  border: 0;
  padding: 0;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 3rem;
  margin: 0 0 1rem;

  &.brand-name {
    font-size: 3rem;
  }
}

h2 {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0 0 1rem;

  &.tagline {
    color: #020db0;
    font-size: 1.3rem;
  }
}
h2.light {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
}

h3 {
  font-weight: 500;
  margin: 0 0 1rem;
}

.bold {
  font-weight: 400;
}

.underline {
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize;
}

.container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem 2rem;
}

.HeroSection {
  position: relative;
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    background-image: url(./assets/zen.jpg);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.7;
  }
}

.HeroSection__inner {
  position: relative;
  text-align: center;
  padding: 0 2rem;
}

.general-page {
  text-align: left;

  h2 {
    margin: 1rem 0;
  }

  p {
    margin: 10px 0;
  }
}

.Header {
  max-width: 800px;
  padding: 2rem 2rem;
  margin: 0 auto;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.Header__menu {
  display: block;
  float: left;
  a {
    margin-right: 1rem;
  }
}

.Footer {
  max-width: 800px;
  padding: 4rem 2rem 2rem;
  margin: 0 auto;
}

.Footer__right {
  float: right;

  a {
    margin: 0 0 0 1rem;
  }
}

.Logo {
  display: inline-block;

  img {
    height: 25px;
    width: auto;
  }
}

.Loading {
  font-size: 20px;
  font-weight: 400;
}

.button {
  font-size: 1rem;
  font-family: 'Playfair Display', serif;
  padding: 0.5rem 1rem 0.7rem;
  outline: none;
  border: 1px solid #333;
  border-radius: 5px;
  text-align: center;
  width: 12rem;
  color: #333;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #333;
  }

  &:active {
    color: #fff;
    background-color: #555;
  }
}

.submit-button {
  margin: 2rem 0 2rem 0;
}

.submit-button--sm-margin-bottom {
  margin: 2rem 0 0.5rem 0;
}

.CTAButton {
  font-size: 1.3rem;
  width: 13rem;
}

.button-info {
  color: #44b493;
  font-size: 14px;
}

.cta-section {
  margin: 5rem auto 2rem;

  .note {
    margin-top: 0.3rem;
    color: #020db0;
    text-decoration: underline;
  }
}

.error {
  color: #FF9494;
  margin: 0;
}

.RequestForm .error {
  color: #fff;
  background: #FF9494;
  margin: 0 0 0.5em 0;
  padding: 0.5em;
}

.form-error-message {
  color: #FF9494;
  margin: 1rem 0 0;
}

// Inputs
input {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 300;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #333;
  outline: none;
  width: 16rem;
  max-width: 100%;
  padding: 0 0 0.2rem 0;
  margin: 1rem 0 0.3rem 0;

  &[type="checkbox"] {
    cursor: pointer;
    width: auto;
    margin-right: 1rem;
  }
}

.ElementsApp input {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
}

// AuthButton
.AuthButton {
  float: right;
  cursor: pointer;

  &:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 700px) {
  h1 {
    font-size: 2rem;

    &.brand-name {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 1.6rem;

    &.tagline {
      font-size: 1.2rem;
    }
  }

  .Header {
    padding: 1.6rem 1.2rem;
  }

  .Footer {
    padding: 3rem 1.2rem 1.6rem;
  }

  .container {
    margin: 0rem auto 0;
    padding: 0 1.2rem;
  }

  .ParallaxSection {  
    height: 300px;
  }
}

.Homepage {
  p {
    max-width: 40rem;
    text-align: center;
    margin: 0 auto 1rem;
    line-height: 1.6rem;
  }

  ul, ol {
    max-width: 30rem;
    margin: auto;
  }
}

ul, ol {
  text-align: left;
  padding-left: 1.2rem;
}

li {
  margin: 0 0 1rem;
}

.MissionSection {
  padding: 4rem 2rem;
  background-color: #8bf0ba;
}

.ParallaxSection {
  /* The image used */
  background-image: url(./assets/forest.jpg);

  /* Set a specific height */
  height: 400px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ClosingSection {
  padding: 6rem 2rem;
  background-color: #7acfd6;
}

// background-color: #7dce94;

// Donation
.button--donation {
  font-family: 'Lato', sans-serif;
  padding: 0.5rem 1rem 0.3rem;
  width: 6rem;
}

.Donation__select-amount {
  margin: 2rem 0;
}

.Donation__amount-buttons {
  button {
    margin-right: 1rem;
    color: #3f9e99;
    background-color: #fcfcfa;
    border: 2px solid #3f9e99;

    &:hover {
      color: #fcfcfa;
      background-color: #3f9e99;
    }

    &.selected {
      color: #fcfcfa;
      background-color: #3f9e99;
    }
  }
}

.Donation__credit-card-info {
  margin: 2rem 0;
}

// Plan
.Plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Plan__premium-selected {
  .Plan__premium {
    .Plan__select-button {
      background-color: #333;
      color: #fff;
    }
  }
}

.Plan__basic-selected {
  .Plan__basic {
    .Plan__select-button {
      background-color: #333;
      color: #fff;
    }
  }
}

.Plan {
  display: inline-block;
  background: #fff;
  border: 3px solid #333;
  border-radius: 1rem;
  padding: 1rem 1.5rem 1.5rem;
  margin: 1rem 0 1rem;
  cursor: pointer;

  &:hover {
    .Plan__select-button {
      background-color: #333;
      color: #fff;
    }
  }
}

.Plan__name {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0 0 1rem;
}

.Plan__price {
  font-size: 2rem;
  margin-bottom: 0.5rem;

  .dollar-sign {
    display: inline-block;
    font-size: 1rem;
    padding: 0.3rem 0.3rem 1rem 0;
    vertical-align: top;
  }

  .price {
    font-weight: 400;
  }

  .frequency {
    font-size: 1.3rem;
  }
}

.Plan__description {
  height: 90px;

  li {
    color: #5b6ba1;
    margin: 0 0 0.3rem;

    &:last-of-type {
      margin: 0;
    }
  }

  a {
    color: #333;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      color: #333;
      border: none;
    }
  }

  a.link {
    color: #3f9e99;
    border: none;
    text-decoration: underline;
  }

  .additional-note {
    color: #333;
  }
}

.Plan__select-button {
  display: block;
  margin: 1rem auto;
  width: 200px;
  text-align: center;

  cursor: pointer;
  color: #333;
  border: 1px solid #333;
  border-radius: 26px;
  background-color: #fff;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 9px 12px;
}

.StripeElement {
  margin-top: 1rem;
}

.Payment__credit-card-info {
  margin-bottom: 2rem;

  .error {
    color: #fa755a;
    margin-top: 0.5rem;
  }

  label {
    font-weight: 500;
  }
}

.Payment__description {
  margin-bottom: 1rem;

}

.ExtraPaymentInfo {
  margin-top: 1rem;

  .note {
    font-size: 0.8rem;
    color: 999;
  }
}

.ExtraPaymentInfo__card {
  width: 50px;
  margin-right: 2px;
}

.Button--light {
  cursor: pointer;
  display: inline-block;
  color: #020db0;
  border: 1px solid #020db0;
  border-radius: 26px;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 12px;

  &:hover {
    color: #fff;
    background-color: #020db0;
  }
}

.Button--dark {
  cursor: pointer;
  display: inline-block;
  color: #fff;
  border: 1px solid #020db0;
  border-radius: 26px;
  background-color: #020db0;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 12px;

  &:hover {
    color: #020db0;
    background-color: #fff;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
