.SettingMenu {
  display: block;
  float: right;
  cursor: pointer;
  position: relative;
  text-align: right;
  width: 80px;
  z-index: 10;

  ul {
    position: absolute;
    right: 0;
    list-style: none;
    visibility: visible;
    display: block;
    margin: 10px 0 0;
    padding: 0.5rem;
    width: 80px;
    text-align: center;
    border: 1px solid #333;
    background-color: #fff;
  }

  li {
    margin: 0;
  }
}