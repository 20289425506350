.BenefitsSection {
  padding: 6rem 2rem;
  background-color: #8bf0ba;

  h2 {
    font-size: 2.2rem;
  }

  .number {
    font-size: 2.7rem;
    color: #020db0;
  }
}